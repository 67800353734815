@font-face {
    font-family: "Inter";
    src: url("Inter-Bold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "Inter";
    src: url("Inter-SemiBold.woff") format("woff");
    font-weight: 600;
}

@font-face {
    font-family: "Inter";
    src: url("Inter-Medium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Inter";
    src: url("Inter-Regular.woff") format("woff");
    font-weight: 400;
}
